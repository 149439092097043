export const APPLICATION_ANALYTICS = 1;
export const APPLICATION_MANAGEMENT = 2;
export const APPLICATION_BOLTS = 3;
export const APPLICATION_ROUTERS = 4;
export const APPLICATION_CONFIGURATION = 5;
export const APPLICATION_PRODUCTION = 6;
export const STATUS_ACTIVE = 'Active';
export const STATUS_INACTIVE = 'Inactive';
export const STATUS_DELETED = 'Deleted';
export const STATUS_MAINTENANCE = 'Maintainance';
export const STATUS_MISBEHAVING = 'Misbehaving';
export const ORGANIZATION_TYPE_END_USER = 2;
export const QUALITY_PRODUCTION = 1;
export const QUALITY_TEST = 2;
export const BOLT_STATUS_NONE = 'None';
export const BOLT_STATUS_ACTIVE = 'Active';
export const BOLT_STATUS_ORDERED = 'Ordered';
export const BOLT_STATUS_REPLACED = 'Replaced';
export const BOLT_STATUS_MISBEHAVING = 'Misbehaving';
export const BOLT_STATUS_INACTIVE = 'Inactive';
export const BOLT_STATUS_ACCEPTED = 'Accepted';
export const BOLT_STATUS_RECEIVED = 'Received';
export const BOLT_STATUS_READY_FOR_DEPLOY = 'ReadyForDeploy';
export const BOLT_STATUS_CONFIGURED = 'Configured';
export const BOLT_STATUS_MAINTENANCE = 'Maintainance';
export const BOLT_STATUS_ROGUE = 20;
export const THEME_LIGHT = 1;
export const THEME_DARK = 2;
export const ORGANIZATION_SLA = 0;
export const ORGANIZATION_SLA_NAME = 'None';
export const ORGANIZATION_OEM = 1;
export const ORGANIZATION_OEM_NAME = 'OEM';
export const ORGANIZATION_EUO = 2;
export const ORGANIZATION_EUO_NAME = 'End User';

export const RECEPTION_STATUS_NEW = 'New';
export const RECEPTION_STATUS_RECEIVED= 'Received';
export const RECEPTION_STATUS_PROGRAMMED = 'Programmed';
export const RECEPTION_STATUS_TEMP_CALIBRATED = 'TempCalibrated';
export const RECEPTION_STATUS_READY = 'Ready';
export const RECEPTION_STATUS_FINISHED = 'Finished';

export const USER_STATUS_ACTIVE = 'Active';
export const USER_STATUS_PENDING = 'Pending';
export const USER_STATUS_DELETED = 'Deleted';
export const USER_STATUS_PASSWORD_RESET = 'PasswordReset';

export const ROLE_NONE = 0;
export const ROLE_USER = 1;
export const ROLE_ADMIN = 2;
export const ROLE_READ = 1;
export const ROLE_CONFIGURE = 2;

export const SEVERITY_CRITICAL = 1;
export const SEVERITY_WARNING = 2;
export const SEVERITY_INFORMATION = 3;

export const NOTIFICATION_STATUS_SOLVED = 1;
export const NOTIFICATION_STATUS_UNSOLVED = 2;

export const GRANULARITY_MINUTES = 1;
export const GRANULARITY_HOURS = 2;
export const GRANULARITY_DAYS = 3;

export const CHANGE_TYPE_USER = 1;
export const CHANGE_TYPE_SYSTEM = 2;

export const ROUTER_DEPLOYMENT_PRODUCTION = 1;
export const ROUTER_DEPLOYMENT_DEVELOPMENT= 2;

export const STRAINLABS_TESTING_ENVIRONMENT = 3;

export const LOGIN_THIRD_PARTY_OKTA = 1;

export const USER_LOGS_LOGIN = 1;

export const CHART_COLORS = ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#2b908f", "#91e8e1", "#009900", "#C0C0C0", "#330000", "#D2617C", "#556b2f", "#8b4513", "#3cb371", "#008080", "#9acd32", "#800080", "#ffa07a", "#00ff7f", "#4169e1", "#00ffff", "#0000ff", "#ff00ff", "#db7093", "#ff1493"];

export const NOTIFICATION_PREFERENCES = [
  { notificationTypeId: 1, notificationTypeTranslated: "Tension too low" },
  { notificationTypeId: 2, notificationTypeTranslated: "Tension too high" },
  { notificationTypeId: 3, notificationTypeTranslated: "Temperature too low" },
  { notificationTypeId: 4, notificationTypeTranslated: "Temperature too high" },
  { notificationTypeId: 5, notificationTypeTranslated: "Battery" },
  { notificationTypeId: 6, notificationTypeTranslated: "Rssi" },
  { notificationTypeId: 7, notificationTypeTranslated: "Lost package" },
];