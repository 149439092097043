import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import i18n from "./translations/i18n";
import VueAxios from "vue-axios";
import axios from 'axios';
import store from "./store/store";
import {initInterceptors} from "@/axios-interceptors";
import applications from "slabasic/src/store/modules/applications";
import SignalR from 'slabasic/src/helper/signalR-hub';
import Clipboard from 'v-clipboard'

Vue.use(Clipboard)
Vue.use(SignalR);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;
initInterceptors();

store.registerModule('applications', applications);

new Vue({
    vuetify,
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app');
