<template>
  <AuthenticationDialog>
    <v-form ref="form" v-model="valid" class="pb-8">
      <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[validations.required, validations.password]"
          :type="showPassword ? 'text' : 'password'"
          class="pb-0 mb-0"
          name="newPassword"
          outlined
          validate-on-blur
          @click:append="showPassword = !showPassword">
        <template v-slot:label>
          {{ $t('authentication.newPassword') }}<sup>*</sup>
        </template>
        <template v-slot:append>
          <v-tooltip  bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="generate"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on">mdi-lock-reset</v-icon>
            </template>
            {{ $t('tooltips.generatePassword') }}
          </v-tooltip>
          <v-tooltip  bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="showPassword = !showPassword"
                      v-bind="attrs"
                      v-on="on">{{showPassword ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
            </template>
            {{showPassword ? $t('tooltips.hidePassword'): $t('tooltips.showPassword') }}
          </v-tooltip>
        </template>
      </v-text-field>
      <v-text-field
          v-model="repeatPassword"
          :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[validations.required, validations.password,  passwordConfirmationRule]"
          :type="showRepeatPassword ? 'text' : 'password'"
          class="pb-0 mb-0"
          name="repeatNewtPassword"
          outlined
          validate-on-blur
          @click:append="showRepeatPassword = !showRepeatPassword"
          @keyup.enter="activateAccount">
        <template v-slot:label>
          {{ $t('authentication.repeatNewPassword') }}<sup>*</sup>
        </template>
        <template v-slot:append>
          <v-tooltip  bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="generate"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on">mdi-lock-reset</v-icon>
            </template>
            {{ $t('tooltips.generatePassword') }}
          </v-tooltip>
          <v-tooltip  bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="showRepeatPassword = !showRepeatPassword"
                      v-bind="attrs"
                      v-on="on">{{showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
            </template>
            {{showRepeatPassword ? $t('tooltips.hidePassword'): $t('tooltips.showPassword') }}
          </v-tooltip>
        </template>
      </v-text-field>
      <v-btn
          :disabled="loading"
          :loading="loading"
          block
          class="shadow font-weight-bold login text-h5 danger secondary--text rounded-lg mt-10 reset-password pa-8"
          x-large
          @click="activateAccount">
        {{ $t('authentication.confirmAccount') }}
      </v-btn>
    </v-form>
  </AuthenticationDialog>
</template>

<script>
import AuthenticationDialog from "./AuthenticationDialog/AuthenticationDialog";
import validations from "slabasic/src/helper/validations";
import {generatePassword} from "slabasic/src/helper/helpers";

export default {
  name: "ActivateAccount",
  components: {AuthenticationDialog},
  data() {
    return {
      valid: false,
      password: '',
      repeatPassword: '',
      loading: false,
      validations,
      showPassword: false,
      showRepeatPassword: false
    };
  },
  mounted() {
    this.tokenValidation();
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.password === this.repeatPassword) || this.$t('authentication.passwordsNotMatch');
    },
  },
  methods: {
    generatePassword,
    tokenValidation() {
      this.axios.post(this.$store.getters.getUrl('confirmToken'),
          {
            token: this.$route.query.token,
            email: this.$route.query.email,
          }
      ).catch((error) => {
        this.$store.dispatch('addNotification',
            {
              type: 'error',
              message: error.response.data.messages
            });
        this.$router.push({name: 'login'});
      });
    },
    activateAccount() {
      if (!this.valid) {
        return;
      }
      this.loading = true;
      this.axios.post(this.$store.getters.getUrl('confirmUserAccount'),
          {
            token: this.$route.query.token,
            password: this.password,
            confirmPassword: this.repeatPassword,
          }
      ).catch((error) => {
        this.$store.dispatch('addNotification',
            {
              type: 'error',
              message: error.response.data.messages
            });
      }).finally(() => {
        this.loading = false;
        this.$router.push({name: 'login'});
      });
    },
    generate() {
      const password = this.generatePassword();
      this.password = password;
      this.repeatPassword = password;
      this.showPassword = true;
      this.showRepeatPassword = true;
      this.$clipboard(password);
      this.$store.dispatch('addNotification',
          {
            type: 'success',
            message: this.$t('success.passwordCopied')
          });
      setTimeout(() => {
        this.$refs.form.validate();
      }, 10);
    }
  }
};
</script>
