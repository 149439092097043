<script>
export default {
  name: "ConfirmEmail",
  created() {
    this.axios.post(this.$store.getters.getUrl('confirmEmail'),
      {
        token: this.$route.query.token,
        email: this.$route.query.email
      }
    ).then(() => {
      this.emitter.emit('addNotification',
        {
          type: 'success',
          message: this.$t('success.activateEmailSuccessful')
        });
    }, (error) => {
      this.emitter.emit('addNotification',
        {
          type: 'error',
          message: error.response.data.errorMessages.join(' ')
        });
    }).finally(() => {
      this.$router.push({name: 'login'});
    });
  },
};
</script>
