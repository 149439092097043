import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from "slabasic/src/helper/colors";
import store from "@/store/store";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: store.getters.getTheme === 'dark',
        themes: {
            light: {
                primary: colors.primary,
                secondary: colors.secondary,
                accent: colors.accent,
                danger: colors.danger,
                warning: colors.warning,
                success: colors.success,
            },
            dark: {
                primary: colors.secondary,
                secondary: colors.primary,
                accent: colors.accent,
                danger: colors.danger,
                warning: colors.warning,
                success: colors.success,
            }
        },
        iconfont: 'mdi'
    }
});
