<template>
  <v-app>
    <router-view/>
    <NotificationList/>
    <ContactUsDialog/>
    <LoaderOverlay/>
    <LogoutDialog/>
  </v-app>
</template>

<script>

import NotificationList from "slabasic/src/components/Notification/NotificationList";
import ContactUsDialog from "slabasic/src/components/ContactUs/ContactUsDialog";
import LogoutDialog from "slabasic/src/components/LogoutDialog/LogoutDialog";
import LoaderOverlay from "slabasic/src/components/LoaderOverlay/LoaderOverlay.vue";

export default {
  name: 'App',
  components: {LoaderOverlay, NotificationList, ContactUsDialog, LogoutDialog},
  mounted() {
    document.title = 'Strainlabs';
    this.$store.dispatch('refreshToken');
  },
  data: () => ({
    //
  }),
}
;
</script>

<style lang="scss">
@import "~@/styles/app";
</style>