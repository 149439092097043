const state = {
    organizationType: null,
    organizationId: null,
    organizationName: null,
    previousManager: {},
    canChangeManager: false,
    organizationDialog: false,
    onlyOneOrganizationForApp: false,
};

const getters = {
    getManagerOrgType: (state, a_, b_, rootGetters) => state.organizationType ?? rootGetters.getOrganizationType,
    getManagerOrgId: (state, a_, b_, rootGetters) => state.organizationId ?? rootGetters.getOrganizationId,
    getManagerOrgName: (state, a_, b_, rootGetters) => state.organizationName ?? rootGetters.getOrganizationName,
    isManageAs: (state, a_, b_, rootGetters) => {
        return state.organizationId !== rootGetters.getOrganizationId && state.organizationId !== null;
    },
    hasPreviousManager: (state) => Object.keys(state.previousManager).length > 0,
    canChangeManager: (state) => state.canChangeManager,
    organizationDialog: (state) => state.organizationDialog,
    onlyOneOrganizationForApp: (state) => state.onlyOneOrganizationForApp,
};
const actions = {
    async setManagerOrgType({commit}, orgType) {
        await commit('setManagerOrgType', orgType);
    },
    async setManagerOrgId({commit}, orgId) {
        await commit('setManagerOrgId', orgId);
    },
    async setManagerOrgName({commit}, orgName) {
        await commit('setManagerOrgName', orgName);
    },
    async setManager({commit}, payload) {
        await commit('setManager', payload);
    },
    async setPreviousManager({commit, state}) {
        await commit('setManager', state.previousManager);
    },
    async setChangeManager({commit}, canChangeManager) {
        await commit('setChangeManager', canChangeManager);
    },
    async setOrganizationDialog({commit}, organizationDialog) {
        await commit('setOrganizationDialog', organizationDialog);
    },
    async setOnlyOneOrganizationForApp({commit}, onlyOneOrganizationForApp) {
        await commit('setOnlyOneOrganizationForApp', onlyOneOrganizationForApp);
    },
};
const mutations = {
    setManagerOrgType(state, orgType) {
        state.organizationType = orgType;
    },
    setManagerOrgId(state, orgId) {
        state.organizationId = orgId;
    },
    setManagerOrgName(state, orgName) {
        state.organizationName = orgName;
    },
    setManager(state, payload) {
        if (payload.savePrevious) {
            state.previousManager = {
                organizationId: state.organizationId,
                organizationType: state.organizationType,
                organizationName: state.organizationName
            };
        } else {
            state.previousManager = {};
        }

        state.organizationId = payload.organizationId;
        state.organizationType = payload.organizationType;
        state.organizationName = payload.organizationName;
    },
    setChangeManager(state, canChangeManager) {
        state.canChangeManager = canChangeManager;
    },
    setOrganizationDialog(state, organizationDialog) {
        state.organizationDialog = organizationDialog;
    },
    setOnlyOneOrganizationForApp(state, onlyOneOrganizationForApp) {
        state.onlyOneOrganizationForApp = onlyOneOrganizationForApp;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};