<template>
  <v-footer class="primary secondary--text" padless>
    <div v-if="$route.name === 'profile'" class="ml-5 mt-3 version-numbers primary--text">FE: V{{ FEVersionNumber }}, BE: V{{ BEVersionNumber }}</div>
    <v-container class="mx-8 my-1 footer" fluid>
      <v-row>
        <v-col
          align-self="center"
          class="fade-weak text-caption"
          cols="4">
          Strainlabs Analytics © {{ new Date().getFullYear() }}
        </v-col>
        <v-col
          align-self="center"
          class="text-center"
          cols="4">
          <div class="d-flex justify-center align-center text-caption">
            <span class="fade-weak">{{ $t('footer.poweredBy') }} </span>
            <img :src="require('slabasic/src/assets/img.png')" alt="logo" class="filter-to-inverse-theme"/>
          </div>
        </v-col>
        <v-col
          align-self="center"
          class="text-right pa-2"
          cols="4">
          <v-btn
            v-for="(menu, index) in footerMenu"
            :key="index"
            class="secondary--text fade-weak no-uppercase"
            small
            text
            @click="action(menu.action)">
            {{ menu.label }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    footerMenu() {
      return [
        {
          label: this.$t('footer.termsConditions')
        },
        {
          label: this.$t('footer.dataPrivacy')
        },
        {
          label: this.$t('footer.contact'),
          action: 'contact'
        },
      ];
    },
    BEVersionNumber() {
      return this.$store.getters.getBEVersionNumber;
    },
    FEVersionNumber() {
      return process.env.VUE_APP_VERSION;
    }
  },
  methods: {
    action(type) {
      if(type === 'contact') {
        if(this.isAuthenticated) {
          this.$store.dispatch('setContactUsDialog', true);
        } else {
          this.$router.push({name: 'contactUs'});
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "footer";
</style>