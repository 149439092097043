<template>
    <v-dialog v-model="isContactUsDialog" max-width="500px" @click:outside="close">
      <div class="dialog secondary rounded-lg">
        <div class=" d-flex align-center header justify-center">
          <img
            :src="require('slabasic/src/assets/logo.svg')"
            alt="logo"
            class="filter-to-theme">
        </div>
        <div class="body px-8">
          <ContactUs v-if="isContactUsDialog"/>
        </div>
      </div>
    </v-dialog>
</template>

<script>
import ContactUs from "./ContactUs";

export default {
  name: "ContactUsDialog",
  components: {
    ContactUs
  },
  data() {
    return {
      dialogOpen: true
    }
  },
  computed: {
    isContactUsDialog() {
      return this.$store.getters.isContactUsDialog;
    }
  },
  methods: {
      close() {
        this.$store.dispatch('setContactUsDialog', false)
      }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding: 45px 32px 32px;

  img {
    width: 312px;
    margin-bottom: 20px;
  }
}
</style>