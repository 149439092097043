const state = {
    BASE_URL: process.env.VUE_APP_API_URL,

    login: 'login',
    loginThirdParty: 'login-third-party',
    requestPasswordReset: 'request-password-reset',
    changePassword: 'change-password',
    confirmToken: 'confirm-token',
    confirmUserAccount: 'confirm-user-account',
    confirmEmail: 'confirm-email',
    refresh: 'refresh',
    logout: 'logout',

    getColorTheme: 'organization/1/colortheme',

    myAccount: 'my-account',
    updateAccount: 'update-account',

    contactUs: 'contact-us',
    versionNumber: 'software-version/current',
};
const getters = {
    getUrl: (state) => (name) => {
        return state.BASE_URL + state[name];
    },
};
export default {
    state,
    getters,
};