<template>
  <AuthenticationDialog>
    <v-form v-if="!sentMail" ref="form" v-model="valid" class="pb-8">
      <v-text-field
        v-model="email"
        :rules="[validations.required, validations.email]"
        autocomplete="email"
        outlined>
        <template v-slot:label>
          {{ $t('authentication.email') }}<sup>*</sup>
        </template>
      </v-text-field>
      <span class="text--primary fade-weak">{{ $t('authentication.passwordResetEmail') }}</span>
      <v-btn
        :disabled="loading"
        :loading="loading"
        block
        class="shadow font-weight-bold login text-h5 danger secondary--text rounded-lg mt-16 reset-password pa-8"
        x-large
        @click="forgotPassword">
        {{ $t('authentication.resetPassword') }}
      </v-btn>
      <v-btn
        :disabled="loading"
        :loading="loading"
        block
        class="shadow text-capitalize text-h5 secondary primary--text rounded-lg mt-5 fade-weak pa-8 elevation-3"
        x-large
        @click="$router.back()">
        {{ $t('cancel') }}
      </v-btn>
    </v-form>
    <div v-else class="pb-8">
      <div class="text--primary fade-weak">{{ $t('authentication.passwordResetSent') }}</div>
      <div class="mt-5">{{ $t('authentication.passwordResetNotArrived') }}</div>
      <v-btn
        :disabled="loading"
        :loading="loading"
        block
        class="shadow font-weight-bold login text-h5 danger rounded-lg mt-10 reset-password pa-8"
        x-large
        @click="forgotPassword">
        {{ $t('authentication.resetPassword') }}
      </v-btn>
      <v-btn
        :disabled="loading"
        :loading="loading"
        block
        class="shadow text-capitalize text-h5 white rounded-lg mt-5 fade-weak  pa-8  elevation-3"
        x-large
        @click="$router.back()">
        {{ $t('close') }}
      </v-btn>
    </div>
  </AuthenticationDialog>
</template>

<script>
import AuthenticationDialog from "@/components/Authentication/AuthenticationDialog/AuthenticationDialog";
import validations from "slabasic/src/helper/validations";

export default {
  name: "ForgotPassword",
  components: {AuthenticationDialog},
  data() {
    return {
      loading: false,
      valid: false,
      sentMail: false,
      email: '',
      validations
    };
  },
  methods: {
    forgotPassword() {
      if (!this.valid) {
        return;
      }
      const url = this.$store.getters.getUrl('requestPasswordReset');

      this.loading = true;
      this.axios.post(url, {
        email: this.email
      }).then(() => {
        this.sentMail = true;
      }).catch((err) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: err.response.data.messages
        });
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "forgot-password";
</style>