<template>
  <AppLauncher/>
</template>

<script>

import AppLauncher from "@/components/AppLauncher/AppLauncher";

export default {
  name: 'Home',
  components: {AppLauncher},
  data() {
    return {};
  }
};

</script>
