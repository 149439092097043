import {
    APPLICATION_ANALYTICS,
    APPLICATION_BOLTS, APPLICATION_CONFIGURATION,
    APPLICATION_MANAGEMENT, APPLICATION_PRODUCTION,
    APPLICATION_ROUTERS
} from "../../helper/constants";

const state = {
    apps: [
        {
            id: APPLICATION_ANALYTICS,
            label: 'analytics',
            appName: 'analytics',
            icon: require('slabasic/src/assets/icons/analytics.svg'),
            url: '/analytics'
        },
        {
            id: APPLICATION_BOLTS,
            label: 'bolts',
            appName: 'boltsManagement',
            icon: require('slabasic/src/assets/icons/bolts.svg'),
            url: '/bolts',
        },
        {
            id: APPLICATION_CONFIGURATION,
            label: 'configuration',
            appName: 'configuration',
            icon: require('slabasic/src/assets/icons/configuration.svg'),
            url: '/configuration'
        },
        {
            id: APPLICATION_MANAGEMENT,
            label: 'management',
            appName: 'management',
            icon: require('slabasic/src/assets/icons/management.svg'),
            url: '/management'
        },
        {
            id: APPLICATION_ROUTERS,
            label: 'routers',
            appName: 'routers',
            icon: require('slabasic/src/assets/icons/routers.svg'),
            url: '/routers'
        },
        {
            id: APPLICATION_PRODUCTION,
            label: 'production',
            appName: 'production',
            icon: require('slabasic/src/assets/icons/production.svg'),
            url: '/production'
        },
    ]
};
const getters = {
    getApps(state) {
        return state.apps;
    },
};
const actions = {};
const mutations = {};
export default {
    state,
    getters,
    actions,
    mutations
};