<template>
  <AuthenticationDialog>
    <ContactUs/>
  </AuthenticationDialog>
</template>

<script>
import AuthenticationDialog from "@/components/Authentication/AuthenticationDialog/AuthenticationDialog";
import validations from "slabasic/src/helper/validations";
import ContactUs from "slabasic/src/components/ContactUs/ContactUs";

export default {
  name: "ContactUsPage",
  components: {
    AuthenticationDialog, ContactUs
  },
  data() {
    return {
      loading: false,
      valid: false,
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      validations
    };
  },
  methods: {
    send() {
      this.$refs.form.validate();

      if (!this.valid) {
        return;
      }
      const url = this.$store.getters.getUrl('contactUs');
      this.loading = true;
      this.axios.post(url, {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        message: this.message
      }).then(() => {
        this.$store.dispatch('addNotification', {
          type: 'success',
          message: this.$t('success.messageWasSent')
        });
        this.$router.push({name: 'login'});

      }).catch((err) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: err.response.data.messages
        });
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>