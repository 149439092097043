const messages = {
    en: {
        cancel: 'Cancel',
        create: 'Create',
        close: 'Close',
        id: 'ID',
        delete: 'Delete',
        save: 'Save',
        saveChanges: 'Save changes',
        date: 'Date',
        active: 'Active',
        inactive: 'Inactive',
        pending: 'Pending',
        passwordReset: 'Password Reset',
        deleted: 'Deleted',
        organization: 'Organization',
        organizations: 'Organizations',
        noDataAvailable: 'No data available',
        quantity: 'Quantity',
        send: 'Send',
        reset: 'Reset',
        exportToExcel: 'Export to Excel',
        person: 'Person',
        status: 'Status',
        admin: 'Admin',
        edit: 'Edit',
        user: 'User',
        none: 'None',
        leave: 'Leave',
        name: 'Name',
        type: 'Type',
        selectAll: 'Select all',
        view: 'View',
        search: 'Search',
        time: 'Time',
        yes: 'Yes',
        no: 'No',
        ok: 'Ok',
        appLauncher: {
            analytics: 'Analytics',
            management: 'Management',
            bolts: 'Bolts',
            routers: 'Routers',
            boltsManagement: 'Bolts Management',
            configuration: 'Configuration',
            config: 'Config',
            production: 'Production',
        },
        footer: {
            poweredBy: 'Powered by',
            termsConditions: 'Terms & Conditions',
            dataPrivacy: 'Data & Privacy',
            contact: 'Contact',
        },
        success: {
            activateEmailSuccessful: 'The activation was successful.',
            resendActivationMail: 'The activation mail was resent successfully.',
            resetPasswordSuccess: 'Your password has been reset successfully.',
            organizationUpdated: 'The organization was updated successfully.',
            organizationAdminUpdated: 'The organization admin was updated successfully. Please check the rights of the old organization admin.',
            organizationCreated: 'The organization was created successfully.',
            messageWasSent: 'The message was sent successfully.',
            boltsWereMoved: 'The bolts were moved successfully.',
            passwordCopied: 'Generated password has been copied to clipboard.',
            userAccountWasActivated: 'The user account was activated successfully.',
            passwordWasChange: 'The password was changed. Please log in.',
        },
        errors: {
            invalidEmail: 'Invalid email',
            required: 'Required',
            cannotBeZero: 'This field cannot be zero',
            passwordFormat: 'The password should contain an uppercase, lowercase character, a number and a special character',
            numberFormat: 'The field can only contain numeric values.',
            minimumItem: 'You should add minimum one item to the table.',
            numberDecimalFormat: 'The field can only contain numeric values with one decimal place.',
            numberDecimalFormatLong: 'This field can only contain numeric values with decimal places.',
            savingData: 'There was an error while saving the data.',
            wrongFileType: 'Wrong file type. You can upload ',
            loadedShouldLarger: 'The loaded value cannot be less than the unloaded value.',
            shouldBeLess: 'This value should be less then the %{value} value.',
            uploadingFile: 'There was an error while uploading file.',
            routerConfigTemplateFormat: 'Router template must be a valid JSON and contain: "RouterID", "OrganizationID", "SiteID", "Deployment", "RouterSN", "APIKey", "SSID", "Password" fields in order to be valid',
        },
        warning: {
            areYouSureDelete: 'Are you sure you want to delete this item?',
            permanentlyDeleteItem: 'You are about to permanently delete an item.',
            thisActionPermanent: 'This action is permanent.',
            areYouSureLeave: 'Are you sure you want to leave this page?',
            thereAreUnsavedChanges: 'There are unsaved changes.',
            confirmLeavePage: 'Confirm Leave Page',
            siteCanNotBeDeactivated: 'Site can not be deactivated since has machines or routers attached.',
            machineCanNotBeDeactivated: 'Machine can not be deactivated since has joints attached.',
            jointCanNotBeDeactivated: 'Joint can not be deactivated since has bolts attached.',
            receptionShouldContainBolt: 'The reception should contain at least one bolt.',
            routerBrandCanNotBeDeactivated: 'Router brand can not be deleted since has models attached.',
            areYouSureChangeStatus: 'Are you sure you want to change the status?',
            areYouSureMoveBolts: 'Are you sure you want to move the selected bolts to another organization?',
            areYouSureMoveBoltsReset: 'Bulk moving bolts will assign them to another organization and will change their status to "Configured". Are you sure you want to continue?',
            areYouSureMoveAndResolveBolts: 'Bolts with active notifications cannot be moved. <br/>Would you like to resolve the notifications and move the bolts?',


            someRightsAreChanged: 'Some rights are changed.',
            automaticallyLoggedOut: 'You will be automatically logged out.',
        },
        authentication: {
            email: 'Email',
            password: 'Password',
            newPassword: 'New password',
            repeatNewPassword: 'Repeat new password',
            keepMeLoggedIn: 'Keep me logged in',
            resetPassword: 'Reset password',
            login: 'Login',
            passwordResetEmail: 'A password reset email will be sent to you.',
            passwordResetSent: 'Your password reset email was sent to',
            passwordResetNotArrived: 'It takes a few minutes for the email to arrive. Still no email after 10 minutes? Resend it below.',
            passwordsNotMatch: 'Password and confirm password do not match.',
            confirmAccount: 'Confirm account',
            strainlabsMonitoringSolution: 'Strainlabs System is a complete bolt preload monitoring solution.',
            forMoreInformation: 'For more information about our analytical software, %{contactUs} or go to %{strainlabsAnalytics}.',
            contactUs: 'contact us',
            strainlabsAnalytics: 'Strainlabs Analytics',
            savePassword: 'Save Password',
        },
        profile: {
            firstName: 'First Name',
            lastName: 'Last Name',
            language: 'Language',
            confirmNewPassword: 'Confirm new password',
            currentPassword: 'Current password',
            signOut: 'Sign Out',
            myAccount: 'My account',
            english: 'English',
            german: 'German',
            swedish: 'Swedish',
            newEmail: 'New email',
            confirmNewEmail: 'Confirm new email',
            emailsNotMatch: 'New email and confirm new email do not match.',
            emailShouldBeDifferent: 'New email should be different from the current email.',
            currentEmail: 'Current Email',
            light: 'Light',
            dark: 'Dark',
            notificationPreferences: 'Notification preferences'
        },
        contactUs: {
            message: 'Message',
        },
        management: {
            organization: {
                companyName: 'Company Name',
                companyNo: 'Company No.',
                theme: 'Theme',
                vatId: 'VAT ID',
                country: 'Country',
                city: 'City',
                county: 'County',
                zipCode: 'Zip Code',
                street: 'Street',
                address: 'Address',
                phone: 'Phone',
                company: 'Company',
                contactPerson: 'Contact person',
                companyAddress: 'Company Address',
                billingAddress: 'Billing Address',
                useCompanyAddressForBilling: 'Use company address for billing',
                useContactPerson: 'Use contact person for admin',
                subscription: 'Subscription',
            },
            organizationRights: 'Organization rights',
            siteRights: 'Site rights',
            configure: 'Configure',
            read: 'Read',
            oem: 'OEM',
            euo: 'EUO',
            endUser: 'End user | End users',
            users: 'Users',
            username: 'Username',
            access: 'Access',
            usersRights: 'Users Rights',
            endUserOrganization: 'End User Organization',
            applications: 'Applications',
            changeAdmin: 'Change Admin',
            notificationTemplate: 'Notification template',
            notificationTemplates: 'Notification templates',
            notificationTemplateEdit: 'Notification template',
            report: 'Report',
            reports: 'Reports',
            reportHistory: 'Report History',
            reportEdit: 'Reports',
            lastSent: 'Last sent',
            sentDatetime: 'Sent Datetime',
            downloadLink: 'Download Link',
            recipients: 'Recipients',
            frequency: 'Frequency',
            selectTemplate: 'Select a notification template or configure it to yourself',
            selectFrequency: 'Please select a frequency',
        },
        bolts: {
            orders: 'Orders',
            receptions: 'Receptions',
            configuration: 'Configuration',
            boltType: 'Bolt Type | Bolt Types',
            iso: 'ISO',
            size: 'Size',
            strength: 'Strength',
            length: 'Length',
            proofLoad: 'Proof Load',
            status: 'Status',
            exportToExcel: 'Export to Excel',
            serialNumber: 'Serial Number',
            name: 'Name',
            quality: 'Quality',
            boltId: 'Bolt ID',
            databaseRow: 'Database Row',
            boltDatabaseRow: 'Bolt Database Row',
            serialNo: 'Serial No',
            loaded: 'Loaded',
            unloaded: 'Unloaded',
            editBolt: 'Edit Bolt',
            site: 'Site | Sites',
            machine: 'Machine',
            joint: 'Joint',
            boltName: 'Bolt Name',
            test: 'Test',
            production: 'Production',
            boltHistory: 'Bolt History',
            tensionHistory: 'Tension History',
            changeType: 'Change Type',
            changeBy: 'Change By',
            deallocateBolt: 'Deallocate Bolt',
            areYouSureDeallocate: 'Are you sure you want to deallocate this bolt?',
            bulkOrganizationChange: 'Bulk organization change',
            changeOrganization: 'Change Organization',
            moveBolts: 'Move Bolts',
            kFactor: 'K factor',
        },
        configuration: {
            configuration: 'Configuration',
            boltType: 'Bolt Type',
            createBoltType: 'Create Bolt Type',
            editBoltType: 'Edit Bolt Type',
            createSize: 'Create Size',
            editSize: 'Edit Size',
            createISO: 'Create ISO',
            editISO: 'Edit ISO',
            editStrength: 'Edit Strength',
            createStrength: 'Create Strength',
            editProofLoad: 'Edit Proof Load',
            createProofLoad: 'Create Proof Load',
            description: 'Description',
        },
        receptions: {
            receptionNo: 'Reception No.',
            organization: 'Organization',
            note: 'Note',
            status: 'Status',
            no: 'No.',
            quantity: 'Quantity',
            receptionDetails: 'Reception Details',
            editReception: 'Edit Reception',
            createReception: 'Create Reception',
            editReceptionDetail: 'Edit Reception Detail',
            createReceptionDetail: 'Create Reception Detail',
            batchNr: 'Batch Number',
            programmed: 'Programmed',
            processPerformer: 'Process Performer',
            new: 'New',
            planned: 'Planned',
            tempCalibrated: 'Temp Calibrated',
            ready: 'Ready',
            finished: 'Finished',
            receivingOrganization: 'Receiving Organization',
            finalReceivingOrganization: 'Final Receiving Organization',
            moveToOrderingOrganization: 'Move to ordering organization',
        },
        orders: {
            orderDetails: 'Order details',
            orderNo: 'Order No.',
        },
        boltStatus: {
            ordered: 'Ordered',
            replaced: 'Replaced',
            misbehaving: 'Misbehaving',
            accepted: 'Accepted',
            received: 'Received',
            maintenance: 'Maintenance',
            configured: 'Configured',
            readyForDeploy: 'Ready for deploy',
            rogue: 'Rogue',
        },
        config: {
            sites: 'Sites',
            offset: 'Offset',
            machines: 'Machines',
            joints: 'Joints',
            siteName: 'Site Name',
            jointName: 'Joint Name',
            select: 'Select',
            timezone: 'Timezone',
            latitude: 'Latitude',
            longitude: 'Longitude',
            searchForOrganizations: 'Search for OEM and End User Organization',
            searchForEUO: 'Search for End User Organization',
            floorPlan: 'Floor plan',
            map: 'Map',
            machineName: 'Machine name',
            machineImage: 'Machine Image',
            minimumAllowedLoad: 'Minimum allowed load',
            minimumSafetyLoad: 'Minimum safety load',
            maximumSafetyLoad: 'Maximum safety load',
            apiKey: 'API Key',
            wifiSSID: 'Wifi SSID',
            wifi: 'Wifi',
            downloadConfigFile: 'Download config file',
            configFile: 'config file',
            allChildWillDeactivated: 'This %{item} and all their dependencies will be deactivated.',
            allChildWillActivated: 'This %{item} and all their dependencies will be activated.',
            routerName: 'Router name',
        },
        imageUploader: {
            dragAndDrop: 'Drag & Drop here',
            uploadLogo: 'Upload logo',
            uploadFloorPlan: 'Upload floor plan',
            uploadMachineImage: 'Upload machine image',
            uploadJointImage: 'Upload joint image',
            logoAspectRatio: 'Upload 4:1 aspect ratio .png or .jpg logo',
            anyAspectRatio: 'Any aspect ratio .png or .jpg',
            changeImage: 'Change Image',
        },
        tooltips: {
            fullScreen: 'Full Screen',
            exitFullScreen: 'Exit full screen',
            fitToWindow: 'Fit to window',
            actualSize: 'Actual size',
            zoomIn: 'Zoom In',
            zoomOut: 'Zoom Out',
            history: 'History',
            activate: 'Activate',
            inactivate: 'Inactivate',
            accessRights: 'Access rights',
            manageAs: 'Manage as',
            resendConfirmationMail: 'Resend account confirmation mail',
            view: 'View',
            setTemporaryPassword: 'Set temporary password',
            showPassword: 'Show Password',
            hidePassword: 'Hide Password',
            generatePassword: 'Generate Password',
            cannotEdit: 'Cannot edit a reception with active bolts'
        },
        imageMarker: {
            dropPin: 'Drop pin',
            done: 'Done',
        },
        routers: {
            brand: 'Brand | Brands',
            brandModels: 'Brand Models',
            brandName: 'Brand Name',
            editRouterBrand: 'Edit Router Brand',
            createRouterBrand: 'Create Router Brand',
            editRouterModel: 'Edit Router Model',
            createRouterModel: 'Create Router Model',
            brandId: 'Brand ID',
            routerName: 'Router Name',
            model: 'Model',
            routerConfigTemplate: 'Router Config Template',
            serial: 'Serial',
            routerDescription: 'Router Description',
            routerBrand: 'Router Brand',
            routerModel: 'Router Model',
            deployment: 'Deployment',
            development: 'Development',
        },
        analytics: {
            notification: 'Notification | Notifications',
            severity: 'Severity',
            title: 'Title',
            critical: 'Critical | Critical',
            warning: 'Warning | Warnings',
            information: 'Information | Information',
            image: 'Image',
            list: 'List',
            setMaintenance: 'Set Maintenance',
            setActive: 'Set Active',
            machine: 'Machine | Machines',
            joint: 'Joint | Joints',
            bolt: 'Bolt | Bolts',
            activeBolts: 'Active Bolts',
            totalBoltsNo: 'Total Bolts No',
            router: 'Router | Routers',
            thereAreNoMachines: 'There are no machines.',
            thereAreNoSites: 'There are no sites.',
            thereAreNoJoints: 'There are no joints.',
            thereAreNoBolts: 'There are no bolts.',
            thereAreNoRouters: 'There are no routers.',
            thereAreNoNotifications: 'There are no notifications.',
            variation: 'Variation',
            deviation: 'Deviation',
            stock: 'Stock',
            realTime: 'Real Time',
            tension: 'Tension',
            temperature: 'Temperature',
            battery: 'Battery',
            time: 'Time (site)',
            rssi: 'RSSI (dB)',
            readings: 'Readings',
            minutes: 'Minutes',
            hours: 'Hours',
            days: 'Days',
            granularity: 'Granularity',
            resolveNotification: 'Resolve notification',
            resolve: 'Resolve',
            solved: 'Solved',
            unsolved: 'Unsolved',
            system: 'System',
            thereAreNoDataReadings: 'There are no data readings for this period.',
            filter: 'Filter',
            dataReadings: 'Data Readings',
            all: 'All',
            startBoltId: 'Start Bolt Id',
            endBoltId: 'End Bolt Id',
            startBoltDatabaseRow: 'Start Bolt Database Row',
            endBoltDatabaseRow: 'End Bolt Database Row',
            between: 'Between',
            above: 'Above',
            below: 'Below',
            outside: 'Outside',
            minTension: 'Min Tension',
            maxTension: 'Max Tension',
            tensionValue: 'Tension Value',
            temperatureValue: 'Temperature Value',
            minTemperature: 'Min Temperature',
            maxTemperature: 'Max Temperature',
            statistics: 'Statistics',
            eventTypes: 'Event Types',
            containsText: 'Contains Text',
            logs: 'Logs',
            SLALogs: 'SLA Logs',
            oktaLogs: 'Okta Logs',
            usersLogins: "User's logins",
            lastLogin: 'Last login',
            lastAccess: 'Last access',
            accessType: 'Access type',
            oktaLog: 'Okta Log',
            averagesVariation: 'Averages (Variation)',
            individualDeviation: 'Individual (Deviation)',
            dynamic: 'Dynamic',
            savedFilters: 'Saved Filters',
            saveAdvancedFilter: 'Save advanced filter',
            filterName: 'Filter Name',
            testBench: 'Test Bench',
            details: 'Details',
            resolveSelected: 'Resolve Selected',
            resolveAll: 'Resolve All',
            resolveAllNotifications: 'Resolve All Notifications',
            resolveForSeverity: 'Resolve for severity',
            startDate: 'Start date',
            endDate: 'End date',
            boltsInPosition: 'Bolts in position',
            routersInPosition: 'Routers in position',
            online: 'Online',
            offline: 'Offline',
            availableToPosition: 'available to position',
            overview: 'Overview',
            lastAverageTension: 'Last average tension',
            lastTotalTension: 'Last total tension',
            lastTension: 'Last tension',
            addedBy: 'Added by',
            callType: 'Call type',
            callScope: 'Call scope',
            request:    'Request',
            response: 'Response',
            success: 'Success',
            failed: 'Failed',
            rawTension: 'Raw tension',
            boltSelected: 'Bolt selected | Bolts selected',
            outOfDelayed: 'out of which {count} has delayed contact | out of which {count} have delayed contact',
        }
    },
    ro: {

    },
};
export default messages;
