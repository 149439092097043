<template>
  <BasicPage>
    <v-container class="app-launcher py-5" fluid>
      <v-row align="center" justify="center" class="mx-auto" :class="'items-' + availableApplicationList.length">
        <v-col
          v-for="application in availableApplicationList"
          :key="application.id"
          class="py-0"
          cols="auto">
          <div class="place-holder d-flex align-center">
            <a
              :href="application.url"
              class="app-container secondary rounded-lg text-center ma-5 d-flex align-center justify-center flex-column shadow">
              <img :src="application.icon" alt="app" class="filter-to-theme app-icon"/>
              <div class="app-name text-uppercase font-weight-bold fade-weak  text-h5">
                {{ $t('appLauncher.' + application.label) }}
              </div>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </BasicPage>
</template>

<script>
import BasicPage from "slabasic/src/components/BasicPage/BasicPage";

export default {
  name: "AppLauncher",
  components: {
    BasicPage
  },
  computed: {
    applications() {
      return this.$store.getters.getApps;
    },
    availableApplicationList() {
      return this.applications.filter(app => this.availableApplications.includes(app.id));
    },
    availableApplications() {
      return this.$store.getters.getAvailableApps;
    }
  },
  methods: {
    redirect(url, newTab = false) {
      if (url) {
        if (newTab) {
          window.open(url, '_blank');
        } else {
          window.location.href = url;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "app-launcher";
</style>