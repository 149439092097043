<template>
  <div>
    <div class="authentication-dialog d-flex align-center justify-center py-2">
      <div
        class="elevation-0 ma-0 shadow authentication">
        <div class="dialog secondary rounded-lg">
          <div class=" d-flex align-center header justify-center">
            <img
              :src="require('slabasic/src/assets/logo.svg')"
              alt="logo"
              class="filter-to-theme">
          </div>
          <div class="body px-8">
            <slot/>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "slabasic/src/components/Footer/Footer";

export default {
  name: "AuthenticationDialog",
  components: {
    Footer
  },
  data() {
    return {
      dialog: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "authentication-dialog";

</style>