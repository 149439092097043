<template>
  <div class="basic-page">
    <Header/>
    <v-main>
      <div class="content">
        <div class="page d-flex">
          <slot/>
        </div>
      </div>
    </v-main>
    <Footer/>
  </div>
</template>

<script>
import Footer from "slabasic/src/components/Footer/Footer";
import Header from "slabasic/src/components/Header/Header";

export default {
  name: "BasicPage",
  components: {Header, Footer}
};
</script>

<style lang="scss" scoped>
@import "basic-page";
</style>