import axios from "axios";
import jwt_decode from "jwt-decode";
import {
    APPLICATION_ANALYTICS,
    APPLICATION_BOLTS,
    APPLICATION_CONFIGURATION,
    APPLICATION_MANAGEMENT, APPLICATION_PRODUCTION,
    APPLICATION_ROUTERS,
    ROLE_NONE, ROLE_USER
} from "../../helper/constants";
import * as Cookies from 'js-cookie';
import router from "@/router";
/* eslint-disable */
const state = {
    user: null,
    language: 'en',
    theme: 'light',
    tokenInformation: {},
    loading: false,
    rememberMe: true,
    email: '',
};
let refreshTokenPromise = null;

const getters = {
    isAuthenticated: state => !!state.user,
    isRememberMe: state => state.rememberMe,
    getUser: state => state.user,
    getEmail: state => state.email,
    getOrganizationId: (state, getters) => {
        const appsPerOrganizations = getters.getAppsPerOrganization;
        if (appsPerOrganizations.length === 1) {
           return appsPerOrganizations[0].O;
        }
        return 0;
    },
    getOrganizationName: (state, getters) => {
        const organizationMeta = getters.getOrganizationMeta;
        if (organizationMeta) {
            return organizationMeta.organizationName
        }

        return '';
    },
    getToken: state => state.user ? state.user.accessToken : false,
    getLanguage: state => state.language,
    getTheme: state => state.theme,
    getTokenInformation: state => state.tokenInformation,
    getOrganizationType: (state, getters) => {
        const appsPerOrganizations = getters.getAppsPerOrganization;
        if (appsPerOrganizations.length === 1) {
           return  appsPerOrganizations[0].T;
        }
        return 2;
    },
    getOrganizationMeta: (_, getters) => {
        const organizationLogoList = getters.getUser.organizationLogoList;
        if (organizationLogoList.length === 1) {
            return organizationLogoList[0];
        } else if (organizationLogoList.length > 1) {
            const selectedOrganization = organizationLogoList.filter(organization => organization.organizationId === getters.getManagerOrgId);
            if (selectedOrganization.length) {
                return selectedOrganization[0];
            }
        }

        return null;
    },
    getManagementRight: (_, getters) => getters.getAppRight(APPLICATION_MANAGEMENT),
    getRouterRight: (_, getters) => getters.getAppRight(APPLICATION_ROUTERS),
    getBoltsRight: (_, getters) => getters.getAppRight(APPLICATION_BOLTS),
    getProductionRight: (_, getters) => getters.getAppRight(APPLICATION_PRODUCTION),
    getConfigurationRight: (_, getters) => getters.getAppRight(APPLICATION_CONFIGURATION),
    getAnalyticsRight: (_, getters) => getters.getAppRight(APPLICATION_ANALYTICS),
    getUserId: state => parseInt(state.tokenInformation.nameid),
    getOrganizationsForApp: (_, getters) => (appID) => {
        return  getters.getAppsPerOrganization.filter(organization => organization.A.filter(a => a.App === appID && a.AR > 0).length > 0).map(organization => organization.O);
    },
    getAvailableApps: (state, getters) => {
        try {
            const apps = getters.getAppsPerOrganization;
                   if(apps) {
                       return apps.map(app => app.A.map(a => a.App)).flat(1);
                   }


            return [];
        } catch {
            return [];
        }
    },
    getAppsPerOrganization: state => {
        const apps = JSON.parse(state.tokenInformation['sla/app']);
        if (apps) {
            if (Array.isArray(apps)) {
                return  apps.map(app => app);
            }
        }
    },
    getAppRight: (state, getters) => (appID) => {
        try {
            const appsPerOrganizations = getters.getAppsPerOrganization

            if (getters.getAvailableApps.includes(appID)) {

                if (appsPerOrganizations.length === 1) {

                    const app = appsPerOrganizations[0].A.filter(app => app.App === appID);
                    if (app.length) {
                        return app[0].AR;
                    }
                } else if (getters.getManagerOrgId !== 0) {
                    const organization = appsPerOrganizations.filter(organization => organization.O === getters.getManagerOrgId)
                    if (organization.length) {
                        const app = organization[0].A.filter(app => app.App === appID);
                        if (app.length) {
                              return app[0].AR;
                        }
                    }

                }
                return ROLE_USER;
            } //TODO fix roles

            return ROLE_USER;
        } catch {
            return ROLE_NONE;
        }
    },
    isLoading: state => state.loading,
};
const actions = {
    async login({commit, rootGetters}, user) {
        const url = rootGetters.getUrl('login');
        const response = await axios.post(url, user);
        if (response.data.data.accessToken && !response.data.data.passwordResetToken) {
            await commit('setUser', {...response.data.data, email: user.username});
            const versionKey = process.env.VUE_APP_NAME + 'Version'
            const versionNumber = localStorage.getItem(versionKey);
            if (versionNumber && response.headers['x-app-version'] && response.headers['x-app-version'] !== versionNumber) {
                localStorage.setItem(versionKey, response.headers['x-app-version']);
                location.reload();
            } else if (!versionNumber && response.headers['x-app-version'] ){
                localStorage.setItem(versionKey, response.headers['x-app-version']);
            }
        } else {
            return response.data.data.passwordResetToken;
        }
    },
    async loginThirdParty({commit, rootGetters}, payload) {
        const url = rootGetters.getUrl('loginThirdParty');
        const response = await axios.post(url, payload);
        if (response.data.data.accessToken && !response.data.data.passwordResetToken) {
            await commit('setUser', {...response.data.data, email: payload.username});
            const versionKey = process.env.VUE_APP_NAME + 'Version'
            const versionNumber = localStorage.getItem(versionKey);
            if (versionNumber && response.headers['x-app-version'] && response.headers['x-app-version'] !== versionNumber) {
                localStorage.setItem(versionKey, response.headers['x-app-version']);
                location.reload();
            } else if (!versionNumber && response.headers['x-app-version'] ){
                localStorage.setItem(versionKey, response.headers['x-app-version']);
            }
        } else {
            return response.data.data.passwordResetToken;
        }
    },
    async logout({commit}) {
        let user = null;
        commit('logout', user);
        sessionStorage.clear();
    },
    async removeTokens({commit}) {
        commit('removeTokens');
    },
    loginUserWithRefreshToken({commit, dispatch}, refreshToken) {
        dispatch('refreshToken', refreshToken);
    },
    refreshToken({commit, rootGetters}, token = null) {
        const url = rootGetters.getUrl('refresh');
        if (!rootGetters.isAuthenticated && !token) {
            return;
        }
        if (token) {
            commit('setLoader', true);
        }
        if (refreshTokenPromise) {
            return refreshTokenPromise;
        }
        let refreshToken = token ? token : rootGetters.getUser.refreshToken;
        try {
            const currentDate = Date.parse(rootGetters.getUser.expiration),
                vuex = JSON.parse(Cookies.get('vuex')),
                cookieDate = Date.parse(vuex.auth.user.expiration);
            if (cookieDate > currentDate) {
                refreshToken = vuex.auth.user.refreshToken;
            }

        } catch {
        }
        refreshTokenPromise = axios.post(url, {
            refreshToken: refreshToken,
        }).then((response) => {
            commit('setUser', response.data.data);
            refreshTokenPromise = null;
            const versionKey = process.env.VUE_APP_NAME + 'Version'
            const versionNumber = localStorage.getItem(versionKey);
            if (versionNumber && response.headers['x-app-version'] && response.headers['x-app-version'] !== versionNumber) {
                localStorage.setItem(versionKey, response.headers['x-app-version']);
                location.reload();
            } else if (!versionNumber && response.headers['x-app-version']) {
                localStorage.setItem(versionKey, response.headers['x-app-version']);
            }
            if (token) {
                router.push({name: 'home'});
            }
        }).finally(() => {
            commit('setLoader', false);
        });
        return refreshTokenPromise;
    },
    async setLanguage({commit}, language) {
        commit('setLanguage', language);
    },
    async setRememberMe({commit}, rememberMe) {
        commit('setRememberMe', rememberMe);
    },
    async setTheme({commit}, theme) {
        commit('setTheme', theme);
    },
    async setTokenInformation({rootGetters, commit, dispatch}, payload) {
        const tokenInformation = jwt_decode(rootGetters.getToken);
        await commit('setTokenInformation', tokenInformation);
        if (payload && payload.removeManager) {
            await dispatch('setManager', {
                organizationType: rootGetters.getOrganizationType,
                organizationId: rootGetters.getOrganizationId,
                organizationName: rootGetters.getOrganizationName,
                savePrevious: true,
            });
        }
    },
    setLoader({commit}, loading) {
        commit('setLoader', loading);
    }
};
const mutations = {
    setUser(state, user) {
        state.user = user;
        if (user.email) {
            state.email = user.email;
        }
        state.tokenInformation = jwt_decode(user.accessToken);
    },
    setTheme(state, theme) {
        state.theme = theme;
    },
    removeTokens(state) {
        if(state.user) {
            state.user.accessToken = null;
            state.user.refreshToken = null;
        }
    },
    setPosts(state, posts) {
        state.posts = posts;
    },
    logout(state) {
        state.user = null;
    },
    setLanguage(state, language) {
        state.language = language;
    },
    setRememberMe(state, rememberMe) {
        state.rememberMe = rememberMe;
    },
    setTokenInformation(state, tokenInformation) {
        state.tokenInformation = tokenInformation;
    },
    setLoader(state, loading) {
        state.loading = loading;
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};