const state = {
    tables: {},
    notifications: [],
    isMenuCollapse: false,
    contactUsDialog: false,
};
const getters = {
    getTableSorting: state => (name, defaultSort = 'id') => {
        return state.tables[name] && state.tables[name].sorting ? state.tables[name].sorting : {
            sortBy: defaultSort,
            sortDesc: true,
        };
    },
    getTableFilters: state => name => {
        return state.tables[name] && state.tables[name].filters ? state.tables[name].filters : false;
    },
    getTableSearch: state => name => {
        return state.tables[name] && state.tables[name].search ? state.tables[name].search : false;
    },
    getTablePage: state => name => {
        return state.tables[name] && state.tables[name].page ? state.tables[name].page : false;
    },
    getTableItemsPerPage: state => name => {
        return state.tables[name] && state.tables[name].itemsPerPage ? state.tables[name].itemsPerPage : 10;
    },
    getNotifications: state => state.notifications,
    isMenuCollapse: state => state.isMenuCollapse,
    isContactUsDialog: state => state.contactUsDialog,
};
const actions = {
    async setTableSorting({commit}, table) {
        commit('setTableSorting', table);
    },
    async setTableFilters({commit}, table) {
        commit('setTableFilters', table);
    },
    async setTableSearch({commit}, table) {
        commit('setTableSearch', table);
    },
    async addNotification({commit}, notification) {
        commit('addNotification', notification);
    },
    async changeMenuCollapse({commit}) {
        commit('changeMenuCollapse');
    },
    async setContactUsDialog({commit}, value) {
        commit('setContactUsDialog', value);
    }
};
const mutations = {
    setTableSorting(state, table) {
        if (state.tables[table.name]) {
            state.tables[table.name].sorting = table;
            state.tables[table.name].page = table.page ? table.page : 0;
            state.tables[table.name].itemsPerPage = table.itemsPerPage ? table.itemsPerPage : 10;
        } else {
            state.tables[table.name] = {
                sorting: {
                    ...table
                },
                page: table.page ? table.page : 0,
                itemsPerPage: table.itemsPerPage ? table.itemsPerPage : 0,
            };
        }
        const tmpTable = {...state.tables};
        state.tables = {};
        state.tables[table.name] = {...tmpTable[table.name]};
    },
    setTableFilters(state, table) {
        if (state.tables[table.name]) {
            state.tables[table.name].filters = table.filters;
        } else {
            state.tables[table.name] = {
                filters: {
                    ...table.filters
                }
            };
        }
        const tmpTable = {...state.tables};
        state.tables = {};
        state.tables[table.name] = {...tmpTable[table.name]};
    },
    setTableSearch(state, table) {
        if (state.tables[table.name]) {
            state.tables[table.name].search = table.search;
        } else {
            state.tables[table.name] = {
                search: {
                    ...table.search
                }
            };
        }
        const tmpTable = {...state.tables};
        state.tables = {};
        state.tables[table.name] = {...tmpTable[table.name]};
    },
    addNotification(state, notification) {
        state.notifications = [...state.notifications, notification];
    },
    changeMenuCollapse(state) {
        state.isMenuCollapse = !state.isMenuCollapse;
    },
    setContactUsDialog(state, value) {
        state.contactUsDialog = value;
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};