<template>
  <v-alert
    :type="notification.type"
    dismissible>
    {{ notification.message }}
  </v-alert>
</template>


<script>
export default {
  name: "NotificationListItem",
  emits: ['removeItem'],
  props: {
    notification: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "notification-list-item";
</style>