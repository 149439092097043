import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from "slabasic/src/store/modules/auth";
import api from "@/store/modules/api";
import basic from "slabasic/src/store/modules/basic";
import * as Cookies from 'js-cookie';
import manager from "slabasic/src/store/modules/manager";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        BEVersionNumber: ''
    },
    actions: {
        async setBEVersionNumber({commit}, number) {
            commit('setBEVersionNumber', number);
        },
    },
    getters: {
        getBEVersionNumber: state => state.BEVersionNumber,
    },
    mutations: {
        setBEVersionNumber(state, number) {
            state.BEVersionNumber = number;
        },
    },
    modules: {
        auth, api, basic, manager
    },
    plugins: [createPersistedState({
        paths: ['auth.user', 'auth.language', 'auth.theme', 'auth.rememberMe', 'auth.email'],
        storage: {
            getItem: (key) => {
                return Cookies.get(key);
            },
            setItem: (key, value) => {
                let expires = 30;
                try {
                    const rememberMe = JSON.parse(Cookies.get('vuex')).auth.rememberMe;
                    if (!rememberMe) {
                        expires = false;
                    }
                } catch {
                    expires = 30;
                }
                if (Cookies.get(key) && value) {
                    try {
                        const currentDate = Date.parse(JSON.parse(value).auth.user.expiration),
                            cookieDate = Date.parse(JSON.parse(Cookies.get(key)).auth.user.expiration);
                        if (cookieDate > currentDate) {
                            return;
                        }

                    }
                        // eslint-disable-next-line no-empty
                    catch {
                    }
                }
                //

                Cookies.set(key, value, {
                    expires: expires,
                    secure: false,
                    //  domain: process.env.VUE_APP_BASE_FE_DOMAIN,
                    path: '/'
                });
            },
            removeItem: key => Cookies.remove(key)
        },
        async rehydrated(state) {
            await state.dispatch('setTokenInformation');
        }
    })]
});

export default store;