import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import store from "@/store/store";
import ForgotPassword from "@/components/Authentication/ResetPassword/ForgotPassword";
import ResetPassword from "@/components/Authentication/ResetPassword/ResetPassword";
import ActivateAccount from "@/components/Authentication/ActivateAccount";
import Profile from "@/components/Profile/Profile";
import ConfirmEmail from "@/components/Authentication/ConfirmEmail";
import ContactUsPage from "../components/ContactUsPage/ContactUsPage";
import OktaLoginCallback from "../components/Authentication/LoginPage/OktaLoginCallback.vue";


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {requiresAuth: true}
    },
    {
        path: '/contact-us',
        name: 'contactUs',
        component: ContactUsPage,
    },
    {
        path: '/reset-password',
        name: 'forgotPassword',
        component: ForgotPassword,
    },
    {
        path: '/forgot-password',
        name: 'resetPassword',
        component: ResetPassword,
    },
    {
        path: '/confirm-account',
        name: 'activeAccount',
        component: ActivateAccount,
    },
    {
        path: '/change-password',
        name: 'changePassword',
        component: ResetPassword,
    },
    {
        path: '/confirm-email',
        name: 'confirmEmail',
        component: ConfirmEmail,
    },
    {
        path: '/login',
        name: 'login',
        meta: {requiresAuth: false},
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/login/okta/callback',
        name: 'loginOktaCallback',
        component: OktaLoginCallback
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {requiresAuth: true}
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(function (to, from, next) {
    if (to.query && to.query.refresh_token) {
        store.dispatch('loginUserWithRefreshToken', to.query.refresh_token)
    }

    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next('/login');
    } else if (to.meta.requiresAuth === false && store.getters.isAuthenticated) {
        next('/');
    } else {
        next();
    }
    window.scrollTo(0, 0);
});

export default router;
