<template>
  <transition-group
    appear
    class="notification-list fixed z-40"
    name="notification-list"
    tag="div">
    <notification-list-item
      v-for="(notification) in notifications"
      :key="notification.id"
      :notification="notification"
      @remove-item="removeNotification"/>
  </transition-group>
</template>

<script>
import NotificationListItem from "./NotificationListItem";

export default {
  name: "NotificationList",
  components: {
    NotificationListItem
  },
  data() {
    return {
      notifications: [],
      removed: [],
      index: 0,
      durationInSec: 10,
    };
  },
  computed: {
    getNotifications() {
      return this.$store.getters.getNotifications;
    },
  },
  watch: {
    getNotifications: {
      handler() {
        this.addNotification(this.getNotifications[this.getNotifications.length - 1]);
      },
      deep: true
    }
  },
  methods: {
    addNotification(notification) {
      const now = new Date().toISOString();
      if (Array.isArray(notification.message)) {
        this.notifications.unshift({
          'id': now,
          'type': notification.type,
          'message': notification.message.join(' ')
        });
      } else {
        this.notifications.unshift({
          'id': now,
          'type': notification.type,
          'message': notification.message,
        });
      }

      setTimeout(() =>
          this.notifications = this.notifications.filter((notification) => notification.id !== now)
        , this.durationInSec * 1000);
    },
    removeNotification(id) {
      this.notifications = this.notifications.filter((notification) => notification.id !== id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "notification-list";
</style>
