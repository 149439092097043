<template>
  <v-overlay :value="loading">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "LoaderOverlay",
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    }
  }
};
</script>

<style scoped>

</style>