<template>
  <v-dialog v-model="dialogOpen" max-width="500px" @click:outside="close">
    <v-card class="px-2 pt-4">
      <v-card-title class="page-title font-weight-medium pb-6">{{ $t('warning.someRightsAreChanged') }}</v-card-title>
      <v-card-text class="body-1 pb-0">
        {{ $t('warning.automaticallyLoggedOut') }}
      </v-card-text>
      <v-card-actions class="pt-8">
        <v-spacer></v-spacer>
        <v-container>
          <v-row>
            <v-col class="px-2">
              <v-btn
                block
                class="text-h6 py-6"
                color="primary secondary--text"
                large
                @click="close">
                {{ $t('close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LogoutDialog",
  emits: ['accept', 'close'],
  data() {
    return {
      dialogOpen: false
    };
  },
  created() {
    this.$signalR.on('ReceiveUserAccessChange', () => {
      this.dialogOpen = true;
    });
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  },
  watch: {
    isAuthenticated(val) {
      if(val) {
        this.$signalRConnection.start();
      } else {
        this.$signalRConnection.close();
      }

    }
  },
  beforeUpdate() {
    this.$store.dispatch('removeTokens');
  },
  methods: {
    close() {
      this.dialogOpen = false;
      this.$store.dispatch('logout');
      window.location.href = process.env.VUE_APP_APPLAUNCHER_DOMAIN;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-title {
  word-break: normal !important;
}
</style>