import i18n from '@/translations/i18n';

export default {
    required: value => !!value || value === 0 || i18n.t('errors.required'),
    nonZero: value => value != 0 || i18n.t('errors.cannotBeZero'),
    email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !value || pattern.test(value) || i18n.t('errors.invalidEmail');
    },
    password: value => {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{6,}$/;
        return !value || re.test(value) || i18n.t('errors.passwordFormat');
    },
    number: value => {
        const re = /^[0-9]+$/;
        return !value || re.test(value) || i18n.t('errors.numberFormat');
    },
    numberDecimal: value => {
        const re = /^-?\d+(\.\d{1,20})?$/;
        return !value || re.test(value) || i18n.t('errors.numberDecimalFormat');
    },
    numberDecimalLong: value => {
        const re = /^-?\d+(\.\d{1,40})?$/;
        return !value || re.test(value) || i18n.t('errors.numberDecimalFormatLong');
    },
    routerModelTemplate: value => {
        if (value) {
            try {
                const configTemplate = JSON.parse(value);

                /* eslint-disable */
                const validation = configTemplate.hasOwnProperty('RouterID') &&
                    configTemplate.hasOwnProperty('OrganizationID') &&
                    configTemplate.hasOwnProperty('SiteID') &&
                    configTemplate.hasOwnProperty('RouterSN') &&
                    configTemplate.hasOwnProperty('APIKey') &&
                    configTemplate.hasOwnProperty('SSID') &&
                    configTemplate.hasOwnProperty('Password') &&
                    configTemplate.hasOwnProperty('Deployment');
                if (validation) {
                    return true;
                } else {
                    return i18n.t('errors.routerConfigTemplateFormat');
                }
                /* eslint-enable */
            } catch (error) {
                return i18n.t('errors.routerConfigTemplateFormat');
            }
        }

        return true;
    }
};