<template>
  <AuthenticationDialog>
    <v-form ref="form" v-model="valid" class="pb-8">
      <v-text-field
        v-model="password"
        :rules="[validations.required, validations.password]"
        :type="showPassword ? 'text' : 'password'"
        class="pb-0 mb-0"
        name="newPassword"
        outlined
        validate-on-blur>
        <template v-slot:label>
          {{ $t('authentication.newPassword') }}<sup>*</sup>
        </template>
        <template v-slot:append>
          <v-tooltip  bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="generate"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on">mdi-lock-reset</v-icon>
            </template>
            {{ $t('tooltips.generatePassword') }}
          </v-tooltip>
          <v-tooltip  bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="showPassword = !showPassword"
                      v-bind="attrs"
                      v-on="on">{{showPassword ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
            </template>
            {{showPassword ? $t('tooltips.hidePassword'): $t('tooltips.showPassword') }}
          </v-tooltip>
        </template>
      </v-text-field>
      <v-text-field
        v-model="repeatPassword"
        :rules="[validations.required, validations.password,  passwordConfirmationRule]"
        :type="showRepeatPassword ? 'text' : 'password'"
        class="pb-0 mb-0"
        name="repeatNewtPassword"
        outlined
        validate-on-blur
        @keyup.enter="resetPassword">
        <template v-slot:label>
          {{ $t('authentication.repeatNewPassword') }}<sup>*</sup>
        </template>
        <template v-slot:append>
          <v-tooltip  bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="generate"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on">mdi-lock-reset</v-icon>
            </template>
            {{ $t('tooltips.generatePassword') }}
          </v-tooltip>
          <v-tooltip  bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="showRepeatPassword = !showRepeatPassword"
                      v-bind="attrs"
                      v-on="on">{{showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
            </template>
            {{showRepeatPassword ? $t('tooltips.hidePassword'): $t('tooltips.showPassword') }}
          </v-tooltip>
        </template>
      </v-text-field>
      <v-btn
        :disabled="loading"
        :loading="loading"
        block
        class="shadow font-weight-bold login text-h5 danger secondary--text rounded-lg mt-16 reset-password pa-8"
        x-large
        @click="resetPassword">
        {{ isFirstChangePassword ? $t('authentication.savePassword') :$t('authentication.resetPassword') }}
      </v-btn>
      <v-btn
          :disabled="loading"
          :loading="loading"
          block
          class="shadow login text-h5 secondary primary--text  rounded-lg mt-5 reset-password pa-8"
          x-large
          @click="$router.push({name: 'login'})">
        {{ $t('cancel')}}
      </v-btn>
    </v-form>
  </AuthenticationDialog>
</template>

<script>
import validations from "slabasic/src/helper/validations";
import AuthenticationDialog from "@/components/Authentication/AuthenticationDialog/AuthenticationDialog";
import {generatePassword} from "slabasic/src/helper/helpers";

export default {
  name: "ResetPassword",
  components: {AuthenticationDialog},
  data() {
    return {
      loading: false,
      valid: false,
      sentMail: false,
      password: '',
      repeatPassword: '',
      validations,
      showPassword: false,
      showRepeatPassword: false
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.password === this.repeatPassword) || this.$t('authentication.passwordsNotMatch');
    },
    isFirstChangePassword() {
      return this.$route.name === 'changePassword';
    }
  },
  methods: {
    generatePassword,
    resetPassword() {
      if (!this.valid) {
        return;
      }
      const url = this.$store.getters.getUrl('changePassword');

      this.loading = true;
      this.axios.post(url, {
        token: this.$route.query.token,
        password: this.password,
        confirmPassword: this.repeatPassword,
      }).then(() => {
        if (this.isFirstChangePassword) {
          this.$router.push({name: 'login',  params: {email: this.$route.query.email}})
          this.$store.dispatch('addNotification',
              {
                type: 'success',
                message: this.$t('success.passwordWasChange')
              });
        } else {
          this.$store.dispatch('addNotification', {
            type: 'success',
            message: this.$t('success.resetPasswordSuccess')
          });
          this.$router.push({name: 'login'});
        }
      }).catch((error) => {
        this.$store.dispatch('addNotification',
          {
            type: 'error',
            message: error.response.data.messages
          });
      }).finally(() => {
        this.loading = false;
      });
    },
    generate() {
      const password = this.generatePassword();
      this.password = password;
      this.repeatPassword = password;
      this.showPassword = true;
      this.showRepeatPassword = true;
      this.$clipboard(password);
      this.$store.dispatch('addNotification',
          {
            type: 'success',
            message: this.$t('success.passwordCopied')
          });
      setTimeout(() => {
        this.$refs.form.validate();
      }, 10);
    }
  }
};
</script>

<style scoped>

</style>