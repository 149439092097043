<template>
  <v-app-bar absolute color="secondary" dense flat prominent>
    <v-container class="fill-height" fluid>
      <v-row justify="space-between">
        <v-col class="d-flex" cols="auto">
          <div class="d-flex">
            <div class="logo-container d-inline-block mr-5  d-flex">
              <img
                @click="home"
                :src="require('slabasic/src/assets/logo.svg')"
                alt="logo"
                class="filter-to-theme d-block pointer"/>
            </div>
          </div>
          <v-menu
            v-if="(appName !== 'applauncher' || $route.name !== 'home') && availableApplicationList.length"
            right
            rounded="rounded-lg"
            top>
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center"
                   v-bind="attrs"
                   v-on="on">
                <img
                  :src="require('slabasic/src/assets/icons/apps.svg')"
                  alt="logo"/>
                <span v-if="appName !== 'applauncher'"
                      class="ml-5 text-uppercase body-2 font-weight-bold primary--text">{{
                    $t('appLauncher.' + appName)
                  }}</span>
              </div>
            </template>
            <v-list>
              <div :class="'items-' +availableApplicationList.length" class="d-flex applications">
                <a
                  :href="app.url"
                  v-for="app in availableApplicationList" :key="app.id"
                  :class="{'active': appName === app.appName}"
                  class="app-container  rounded-lg text-center ma-1 d-flex align-center justify-center flex-column">
                  <img :src="app.icon" alt="app" class="filter-to-theme app-icon"/>
                  <div class="app-name text-uppercase font-weight-bold fade-weak text-caption">
                    {{ $t('appLauncher.' + app.label) }}
                  </div>
                </a>
              </div>
            </v-list>
          </v-menu>
        </v-col>
        <v-col align-self="center" class="text-right d-flex align-center" cols="auto">
          <template v-if="!isSLA && organizationMeta" class="">
            <div v-if="organizationMeta && organizationMeta.logo" class="d-inline-block mr-4">
              <img :src="organizationMeta && organizationMeta.logo" alt="company-logo" class="company-logo d-flex rounded-lg"/>
            </div>
            <v-btn v-else class="logo-icon rounded-lg mr-4" color="accent" dark fab>
              <v-icon color="primary" dark small>
                mdi-domain
              </v-icon>
            </v-btn>
          </template>
          <span class="text-body-2 font-weight-bold name text-uppercase">{{ organizationMeta && organizationMeta.organizationName }}</span>
          <v-icon v-if="organizationMeta" class="d-none d-md-flex" color="primary">
            mdi-circle-small
          </v-icon>
          <span class="text-body-1 pointer" @click="profile()" @click.middle="profile(true)">{{
              user.userFirstName
            }} {{ user.userLastName }}</span>
          <v-avatar class="ml-3 pointer" color="primary" @click="profile()" @click.middle="profile(true)">
            <v-icon color="secondary">
              mdi-account
            </v-icon>
          </v-avatar>
          <v-menu
            v-if="appName === 'analytics'"
            offset-y
            rounded="rounded-lg">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                bordered
                top
                color="danger accent-4"
                dot
                offset-x="10"
                offset-y="10"
                :value="newNotification">
                <v-avatar class="ml-3 pointer" color="primary" v-bind="attrs"
                          v-on="on" @click="newNotification = false;">
                  <v-icon color="secondary" small>
                    mdi-bell
                  </v-icon>
                </v-avatar>
              </v-badge>
            </template>
            <v-list class="notifications">
              <div class="notification-container">
                <div class="full-height secondary">
                </div>
                <template v-if="notifications.length">
                  <div v-for="(notification, index) in notifications" :key="index" class="px-3 pointer" @click="navigateJoint(notification)">
                    <div class="d-flex justify-space-between py-2">
                      <div class="font-weight-bold">
                        <v-icon class="mr-2" :color="severityIcon(notification.severityId).class" small>
                          {{ severityIcon(notification.severityId).icon }}
                        </v-icon>
                        {{ notification.title }}
                      </div>
                      <div class="pl-2">{{ formatDateTime(notification.date) }}</div>
                    </div>
                    <v-divider class="my-2"/>
                  </div>
                </template>
                <div v-else class="pa-2 px-5 font-weight-bold d-flex justify-center">
                  {{ $t('analytics.thereAreNoNotifications') }}
                </div>
              </div>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import {ORGANIZATION_SLA, SEVERITY_CRITICAL, SEVERITY_WARNING} from "../../helper/constants";
import {formatDateTime} from "slabasic/src/helper/data";

export default {
  name: "Header",
  mounted() {
    if (this.appName === 'analytics') {
      this.getNotifications();
    }
  },
  data() {
    return {
      notifications: [],
      newNotification: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    applications() {
      return this.$store.getters.getApps;
    },
    availableApplicationList() {
      return this.applications.filter(app => this.availableApplications.includes(app.id));
    },
    availableApplications() {
      return this.$store.getters.getAvailableApps;
    },
    appName() {
      return process.env.VUE_APP_NAME;
    },
    organizationMeta() {
      return this.$store.getters.getOrganizationMeta;
    },
    isSLA: function () {
      return this.$store.getters.getOrganizationType === ORGANIZATION_SLA;
    },
    isManageAs() {
      return this.$store.getters.isManageAs;
    },
    managerOrgId() {
      return this.$store.getters.getManagerOrgId;
    },
    newNotifications() {
      return this.$store.getters.getNewNotifications;
    }
  },
  watch: {
    managerOrgId() {
      if (this.appName === 'analytics') {
        this.getNotifications();
      }
    },
    newNotifications: {
      handler() {
        this.newNotification = true;
        this.notifications = [...this.newNotifications, ...this.notifications];
        this.$store.dispatch('refreshNotificationSummary');
      },
      deep: true,
    }
  },
  methods: {
    formatDateTime,
    getNotifications() {
      const url = this.$store.getters.latestNotifications;
      this.axios.get(url).then(({data}) => {
        this.notifications = data.data.items;
      });
    },
    home() {
      if (this.appName === 'applauncher') {
        this.$router.push({name: 'home'});
      } else {
        window.location.href = process.env.VUE_APP_APPLAUNCHER_DOMAIN;
      }
    },
    profile(newTab = false) {
      if (newTab) {
        window.open(process.env.VUE_APP_APPLAUNCHER_DOMAIN + '/profile', '_blank');
      } else {
        if (this.appName === 'applauncher') {
          this.$router.push({name: 'profile'});
        } else {
          window.location.href = process.env.VUE_APP_APPLAUNCHER_DOMAIN + '/profile';
        }
      }
    },
    navigate(to, newTab = false) {
      if (to) {
        if (newTab) {
          window.open(to, '_blank');
        } else {
          window.location.href = to;
        }
      }
    },
    severityIcon(severityId) {
      switch (severityId) {
        case SEVERITY_CRITICAL:
          return {
            icon: 'mdi-alert-circle',
            class: 'danger'
          };
        case SEVERITY_WARNING:
          return {
            icon: 'mdi-alert',
            class: 'warning'
          };
        default:
          return {
            icon: 'mdi-comment-alert',
            class: 'success'
          };
      }
    },
    navigateJoint(notification) {
      if (notification.jointId && notification.machineId && notification.siteId) {
        this.$router.push({
          name: 'joint',
          params: {jointId: notification.jointId, machineId: notification.machineId, siteId: notification.siteId}
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "header";
</style>