var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthenticationDialog',[_c('v-form',{ref:"form",staticClass:"pb-8",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"pb-0 mb-0",attrs:{"rules":[_vm.validations.required, _vm.validations.password],"type":_vm.showPassword ? 'text' : 'password',"name":"newPassword","outlined":"","validate-on-blur":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('authentication.newPassword'))),_c('sup',[_vm._v("*")])]},proxy:true},{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":_vm.generate}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-reset")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('tooltips.generatePassword'))+" ")]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'))])]}}])},[_vm._v(" "+_vm._s(_vm.showPassword ? _vm.$t('tooltips.hidePassword'): _vm.$t('tooltips.showPassword'))+" ")])]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{staticClass:"pb-0 mb-0",attrs:{"rules":[_vm.validations.required, _vm.validations.password,  _vm.passwordConfirmationRule],"type":_vm.showRepeatPassword ? 'text' : 'password',"name":"repeatNewtPassword","outlined":"","validate-on-blur":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.resetPassword.apply(null, arguments)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('authentication.repeatNewPassword'))),_c('sup',[_vm._v("*")])]},proxy:true},{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":_vm.generate}},'v-icon',attrs,false),on),[_vm._v("mdi-lock-reset")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('tooltips.generatePassword'))+" ")]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){_vm.showRepeatPassword = !_vm.showRepeatPassword}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'))])]}}])},[_vm._v(" "+_vm._s(_vm.showRepeatPassword ? _vm.$t('tooltips.hidePassword'): _vm.$t('tooltips.showPassword'))+" ")])]},proxy:true}]),model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}}),_c('v-btn',{staticClass:"shadow font-weight-bold login text-h5 danger secondary--text rounded-lg mt-16 reset-password pa-8",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"block":"","x-large":""},on:{"click":_vm.resetPassword}},[_vm._v(" "+_vm._s(_vm.isFirstChangePassword ? _vm.$t('authentication.savePassword') :_vm.$t('authentication.resetPassword'))+" ")]),_c('v-btn',{staticClass:"shadow login text-h5 secondary primary--text  rounded-lg mt-5 reset-password pa-8",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"block":"","x-large":""},on:{"click":function($event){return _vm.$router.push({name: 'login'})}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }