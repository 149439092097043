<template>
  <AuthenticationDialog>
    <div class="text-center pb-10">
      Login with Okta <br>
      <v-progress-circular
          v-if="loading"
          class="mt-10"
          indeterminate
          size="64"
      ></v-progress-circular>
    </div>
  </AuthenticationDialog>
</template>

<script>
import AuthenticationDialog from "../AuthenticationDialog/AuthenticationDialog.vue";
import {LOGIN_THIRD_PARTY_OKTA} from "slabasic/src/helper/constants";

export default {
  name: 'OktaLoginCallback',
  components: {AuthenticationDialog},
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    this.logout();
    if (this.$route.query.token && this.$route.query.email) {
      this.$store.dispatch('loginThirdParty', {
        type: LOGIN_THIRD_PARTY_OKTA,
        token: this.$route.query.token,
        email: this.$route.query.email
      }).then((passwordResetToken) => {
        if (passwordResetToken) {
          this.$router.push({name: 'changePassword', query: {token: passwordResetToken, email: this.email}});
        } else {
          this.$router.push({name: 'home'});
          sessionStorage.removeItem('analyticsNewSession');
          sessionStorage.removeItem('configurationNewSession');
        }
      }).catch((err) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: err.response.data.messages || err.response.data.errorMessages
        });
        this.$router.push({name: 'login'});
      }).finally(() => {
        this.loading = false;
      });
    } else {
      if (this.$route.query.message) {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: this.$route.query.message
        });
      }
      this.$router.push({name: 'login'});
    }
  },
  methods: {
    logout() {
      if (!this.$store.getters.isAuthenticated) {
        return;
      }
      const url = this.$store.getters.getUrl('logout');
      this.logoutLoading = true;
      this.axios.post(url, {
        refreshToken: this.$store.getters.getUser.refreshToken
      });
      this.$store.dispatch('logout');
    }
  }
}
</script>
<style scoped lang="scss">
@import "okta-login-callback";
</style>