import {HubConnectionBuilder} from '@microsoft/signalr';
import store from "../../../applauncher/src/store/store";
import * as Cookies from 'js-cookie';

export default {
    install(Vue) {
        let connection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_SIGNALR_URL, {
                accessTokenFactory: async () => {
                    if (store.getters.getToken === null) {
                        return store.getters.getToken;
                    }
                    return store.getters.getToken;
                }
            })
            .build();

        let startedPromise = null;
        let firstStart = true,
            refresh = true;

        function start() {
            if (!store.getters.isAuthenticated) {
                return;
            }

            startedPromise = connection.start().catch(err => {
                console.error('Failed to connect with hub', err);
                if (!firstStart && refresh) {
                    store.dispatch('refreshToken');
                    refresh = false;
                }

                firstStart = false;
                connection = new HubConnectionBuilder()
                    .withUrl(process.env.VUE_APP_SIGNALR_URL, {
                        accessTokenFactory: async () => {
                            return JSON.parse(Cookies.get('vuex')).auth.user.accessToken;
                        }
                    })
                    .build();
                return new Promise((resolve, reject) => {
                    setTimeout(() => start().then(resolve).catch(reject), 4000);
                });

            }).then(() => {
                refresh = true;
            });
            return startedPromise;
        }

        connection.onclose(() => start());

        start();
        // use new Vue instance as an event bus
        const signalR = new Vue();


        signalR.start = () => {
            start();

        };
        signalR.close = () => {
            connection.stop();
        };
        // every component will use this.$questionHub to access the event bus
        Vue.prototype.$signalR = connection;
        Vue.prototype.$signalRConnection = signalR;
        //   Vue.prototype.$signalRConnection = connection;
        // Forward server side SignalR events through $questionHub, where components will listen to them
        /*    connection.on('ReceiveUserAccessChange', (value) => {
                signalR.$emit('right-changed', value);
            });*/
    }
};