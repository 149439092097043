<template>
  <v-form ref="form" v-model="valid" class="pb-8">
    <v-text-field
      v-model="firstName"
      :rules="[validations.required]"
      outlined
      placeholder=" ">
      <template v-slot:label>
        {{ $t('profile.firstName') }}<sup>*</sup>
      </template>
    </v-text-field>
    <v-text-field
      ref="email"
      v-model="lastName"
      :rules="[validations.required]"
      class="pb-0 mb-0"
      outlined
      placeholder=" ">
      <template v-slot:label>
        {{ $t('profile.lastName') }}<sup>*</sup>
      </template>
    </v-text-field>
    <v-text-field
      ref="email"
      v-model="email"
      :rules="[validations.required, validations.email]"
      class="pb-0 mb-0"
      outlined
      placeholder=" "
      type="email">
      <template v-slot:label>
        {{ $t('authentication.email') }}<sup>*</sup>
      </template>
    </v-text-field>
    <v-textarea
      v-model="message"
      :rules="[validations.required]"
      class="message"
      outlined
      rows="3">
      <template v-slot:label>
        {{ $t('contactUs.message') }}<sup>*</sup>
      </template>
    </v-textarea>
    <v-btn
      :disabled="loading"
      :loading="loading"
      block
      class="font-weight-bold login text-h5 danger secondary--text rounded-lg pa-8 shadow"
      x-large
      @click="send">
      {{ $t('send') }}
    </v-btn>
    <v-btn
      :disabled="loading"
      :loading="loading"
      block
      class="shadow text-capitalize text-h5 secondary primary--text rounded-lg mt-5 fade-weak pa-8 elevation-3"
      x-large
      @click="cancel">
      {{ $t('cancel') }}
    </v-btn>
  </v-form>
</template>

<script>
import validations from "slabasic/src/helper/validations";

export default {
  name: "ContactUs",
  data() {
    return {
      loading: false,
      valid: false,
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      validations
    };
  },
  mounted() {
    if (this.$store.getters.isAuthenticated) {
      const user = this.$store.getters.getUser;
      this.firstName = user.userFirstName;
      this.lastName = user.userLastName;
      const email = this.$store.getters.getEmail;
      if (email) {
        this.email = email;
      }

    }
  },
  methods: {
    send() {
      this.$refs.form.validate();

      if (!this.valid) {
        return;
      }
      const url = this.$store.getters.getUrl('contactUs');
      this.loading = true;
      this.axios.post(url, {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.email,
        message: this.message
      }).then(() => {
        this.$store.dispatch('addNotification', {
          type: 'success',
          message: this.$t('success.messageWasSent')
        });
        if (this.$store.getters.isAuthenticated) {
          this.$store.dispatch('setContactUsDialog', false);
        } else {
          this.$router.push({name: 'login'});
        }
      }).catch((err) => {
        this.$store.dispatch('addNotification', {
          type: 'error',
          message: err.response.data.messages
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    cancel() {
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch('setContactUsDialog', false);
      } else {
        this.$router.back();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>